<template>
  <v-dialog value="true" persistent width="400px">
    <v-card>
      <v-toolbar color="info" dark>
        <v-toolbar-title>Выберите должность</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-select
          :items="$store.state.orgPositionsNames"
          item-text="name"
          item-value="id"
          v-model="posId"
          label="Должность..."
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" @click="$emit('close')">Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="$emit('input', posId)">Отправить запрос</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
      posId: null
  })
}
</script>
