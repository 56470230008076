<template>
  <v-card width="65%" style="margin: 15px auto">
    <OrgReqDialog
      v-if="orgReqDialogShow"
      @close="orgReqDialogShow = false"
      @input="positionSelected"
    />
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Выбор организации</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row align="baseline">
        <v-col cols="10">
          <v-text-field
            label="Поиск"
            v-model="filter"
            append-icon="mdi-magnify"
            hide-details
          />
        </v-col>
        <v-col>
          <v-btn color="info" to="/organization/profile/create">Создать</v-btn>
        </v-col>
      </v-row>
      <v-simple-table fixed-header>
        <thead>
          <tr>
            <th class="text-left" style="width: 50px"></th>
            <th class="text-left">Название</th>
            <th class="text-left">Город</th>
            <th class="text-left">Улица</th>
            <th class="text-left" style="width: 50px">Дом</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="org in orgs" @click="select(org.id)" style="cursor: pointer;">
            <td class="pa-1">
              <img v-if="org.thumbnail" :src="org.thumbnail" width="50px"/>
              <v-icon v-else size="50px">mdi-factory</v-icon>
            </td>
            <td>{{org.title}}</td>
            <td>{{org.city}}</td>
            <td>{{org.street}}</td>
            <td>{{org.building}}</td>
            <td style="max-width: 50px; text-align: center;">
              <v-tooltip bottom v-if="notInOrg(org.id)">
                <template v-slot:activator="{ on }">
                  <v-btn icon @click.stop="showOrgReqDialog(org.id)" v-on="on">
                    <v-icon>mdi-message</v-icon>
                  </v-btn>
                </template>
                <span>Подать заявку на вступление</span>
              </v-tooltip>
            </td>
          </tr>
          <tr v-if="orgs.length == 0" class="text-center pt-3">
            <td colspan="5">Ничего не найдено</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import OrgReqDialog from './components/OrgReqDialog.vue'

export default {
  components: { OrgReqDialog },
  data: () => ({
    filter: '',
    orgs: [],
    searchRequestTimeout: null,
    orgReqDialogShow: false,
    orgId: null,
  }),
  methods: {
    ...mapActions('organization', ['select']),
    showOrgReqDialog(orgId) {
      this.orgId = orgId
      this.orgReqDialogShow = true
    },
    positionSelected(positionId) {
      this.orgReqDialogShow = false
      this.$axios.post('organization/staff/request', {
        orgId: this.orgId,
        positionId: positionId
      })
    },
    notInOrg(orgId) {
      return !this.$store.state.user.organizationsIds.includes(orgId)
    },
    getOrgs() {
      this.$axios.get('organization/profile/index', { params: { filter: this.filter }})
      .then(r => {
        this.orgs = r.data.slice()
      })
    }
  },
  watch: {
    filter(value) {
      if(value && value.length >= 2) {
        clearTimeout(this.searchRequestTimeout);
        this.searchRequestTimeout = setTimeout(() => this.getOrgs(), 1000);
      }
    }
  },
  mounted() {
    this.getOrgs()
  }
}
</script>
